.header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 9rem 0 4rem 8.5rem;

	@include breakpoint(tab_portrait) {
		padding: 9rem 0;
	}

	&-intro {
		margin-right: 10%;

		@include breakpoint(tab_portrait) {
			text-align: center;
		}
	}

	&__image {
		max-width: 85rem;

		@include breakpoint(tab_portrait) {
			margin-top: 5rem;
		}

		img {
			width: 100%;
		}
	}
}
