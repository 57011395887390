.features {
	position: -webkit-sticky;
	position: sticky;
	top: 8.3rem;
	padding: 0 8.5rem;
	background-color: color(white);

	@include breakpoint(tab_portrait) {
		padding: 0 2rem;
	}
	&__nav {
		&-list {
			display: block;
			white-space: nowrap;
			padding: 1.5rem;
			border: 1px solid color(dark-grey);
			border-left: none;
			border-right: none;
			overflow-x: scroll;
			overflow-y: hidden;
			list-style-type: none;

			&_item {
				display: inline-block;
				text-transform: capitalize;
				color: color(deep-grey);
				&:not(:last-child) {
					margin-right: 3rem;
				}
				&:hover,
				&.active {
					color: color(indigo);
					cursor: pointer;
				}
			}
		}
	}

	&__content {
		padding: 2rem 8.5rem;
		@include breakpoint(tab_portrait) {
			padding: 5rem 2rem;
		}
		&-text {
			p {
				color: color(deep-grey);
				// line-height: 1;
			}
			a {
				color: color(indigo);
				border-bottom: 2px solid color(indigo);
			}
		}
		&-image {
			max-width: 100%;
			margin-top: 1rem;
			margin-bottom: 3rem;
			img {
				width: 100%;
			}
		}

		.nav {
			&-tabs {
				display: block;
				width: 100%;
				border: none;
				background-color: transparent;
				white-space: nowrap;
				overflow-x: scroll;
				text-transform: capitalize;
				li {
					display: inline-block;
					margin-right: 3rem;
					&:active,
					&:hover,
					&:focus-visible {
						border: none;
						outline: none;
					}
					.nav-link {
						padding: 0;
						color: color(deep-grey);
						border: none;

						&.active,
						&:hover,
						&:focus-visible {
							background-color: transparent;
							color: color(indigo);
							cursor: pointer;
							outline: none;

							span {
								display: block;
								width: 8rem;
								margin-top: -5px;
								border-bottom: 3px solid color(indigo);
								border-radius: 5px;
							}
						}
					}
				}
			}
		}
	}
}
