@include keyframes(dropout) {
	0% {
		opacity: 0;
		transform: translateY(-60px);
	}

	80% {
		transform: translateY(10px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@include keyframes(fadeIn) {
	100% {
		opacity: 1;
	}
}

@include keyframes(loader) {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

// @include keyframes(slideIn) {
// 	0% {
// 		right: 0;
// 		width: 5rem;
// 	}

// 	100% {
// 		width: 20rem;
// 	}
// }

@include keyframes(pulse) {
	0% {
		width: 10rem;
	}
	100% {
		width: 15rem;
	}
}
@include keyframes(pulsePlayer) {
	0% {
		width: 35rem;
	}
	100% {
		width: 40rem;
	}
}

@include keyframes(slideIn) {
	0% {
		margin-right: -10rem;
	}

	50% {
		margin-right: -5rem;
	}

	100% {
		margin-right: 0;
	}
}
