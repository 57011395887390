@font-face {
	font-family: 'EuclidCircular';
	font-weight: 200;
	font-style: light;
	src: url('../../font/EuclidCircularA-Light.woff') format('woff');
}
@font-face {
	font-family: 'EuclidCircular';
	font-weight: 300;
	font-style: normal;
	src: url('../../font/EuclidCircularA-Regular.woff') format('woff');
}
@font-face {
	font-family: 'EuclidCircular';
	font-weight: 400;
	font-style: bold;
	src: url('../../font/EuclidCircularA-Medium.woff') format('woff');
}
@font-face {
	font-family: 'EuclidCircular';
	font-weight: 500;
	font-style: bolder;
	src: url('../../font/EuclidCircularA-SemiBold.woff') format('woff');
}
@font-face {
	font-family: 'EuclidCircular';
	font-weight: 700;
	font-style: boldest;
	src: url('../../font/EuclidCircularA-Bold.woff') format('woff');
}
