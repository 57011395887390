.section {
	padding: 5rem 8.5rem;

	@include breakpoint(tab_portrait) {
		padding: 5rem 2rem;
	}

	&-title {
		text-align: center;
	}
	&-subtitle {
		text-transform: uppercase;
		text-align: center;
		color: color(indigo);
	}
	img {
		width: 100%;
	}
	&__body {
		p {
			color: color(deep-grey);
			// line-height: 2;
		}
	}

	.nav {
		justify-content: center;
		background-color: #f0f0fc;
		&-tabs {
			width: fit-content;
			border: none;

			li {
				&:active,
				&:hover,
				&:focus-visible {
					border: none;
					outline: none;
				}
				.nav-link {
					padding: 1rem;
					border-radius: 5px;
					color: color(indigo);
					border: none;
					&:active {
						background-color: color(white);
					}
					&:active,
					&:hover,
					&:focus-visible {
						cursor: pointer;
						outline: none;
					}
				}
			}
		}
	}
	.tab-pane {
		opacity: 0.2;
		&.active {
			animation: fadeIn 0.5s forwards;
		}
	}
}
