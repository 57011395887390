.get-started {
	background: url('../../images/clients-bg.svg');

	.progress {
		width: 100%;
		border-radius: 0;
		background-color: color(lilac);

		&-bar {
			background-color: color(indigo);
		}
	}

	&__content {
		width: 50%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;

		@include breakpoint(tab_landscape) {
			width: 70%;
		}

		@include breakpoint(tab_portrait) {
			width: 100%;
		}

		.back {
			position: absolute;
			top: 5rem;
			left: 25rem;
			cursor: pointer;

			@include breakpoint(tab_portrait) {
				display: block;
				width: 4rem;
				height: 4rem;
				top: -8rem;
				left: auto;
			}
		}

		h2 {
			text-align: center;
		}

		// p {
		// 	color: color(deep-grey);
		// }

		& .user-count {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 1.5rem;
			background-color: color(lilac);

			&__number {
				div {
					display: block;
					color: color(indigo);
					border: 1px solid rgba(106, 105, 228, 0.2);
					background: #ffffff;
					padding: 0.5rem 0;
					border-radius: 4px;
				}

				input {
					padding: 0 1rem;
					border: none;
					outline-color: color(indigo);
				}
			}

			&__amount {
				text-align: right;
				color: color(text);

				.total-amount {
					text-align: right;
					font-size: 1.3rem;
					line-height: 1;
					color: color(deep-grey);
				}

				.user-price {
					color: color(indigo);
				}

				.patient-pricing {
					font-size: 1.3rem;
					border-radius: 10px;
					padding: 0.2rem 0.5rem;
					background-color: #43667626;
				}
			}
		}
	}

	.form-check-input {
		margin-top: 0.8rem;

		&:focus {
			border-color: color(indigo);
			box-shadow: 0 0 0 0.25rem color(lilac);
		}
	}

	.form-check-input:checked {
		background-color: color(indigo);
		border: 1px solid color(indigo);
		outline: color(indigo);
	}
}

.preview {
	margin: 1.5rem 0;
	border-radius: 10px;

	&__container {
		padding: 1.5rem;

		.selected-bar {
			position: absolute;
			top: 0;
			left: 0;
			width: 0.5rem;
			height: 100%;
			background-color: color(indigo);
		}

		&-status {
			float: right;
			color: color(indigo);
		}
	}
}

.added-services {
	margin: 4rem 0;

	&__list {
		padding: 2rem;

		p {
			color: color(text) !important;
			margin-bottom: 0.5rem;
		}
	}
}

.thankyou {
	display: flex;
	flex-direction: column;
	align-items: center;

	img.mailbox {
		width: 40rem;
		height: 40rem;
	}
}
