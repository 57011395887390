.services {
	display: flex;
	justify-content: center;

	&-details {
		margin-right: 10rem;
	}

	&-image {
		max-width: 50rem;
		margin: 2rem 0;
	}

	@include breakpoint(tab_portrait) {
		flex-wrap: wrap;

		&-image {
			margin: auto;
		}
	}
}

.functions {
	padding: 5rem 0;

	&__content {
		text-align: center;

		&-video {
			display: flex;
			justify-content: center;
			position: relative;
			margin: 5rem auto;
			height: 60rem;
			width: 60%;
			border-radius: 10px;
			background-color: color(white);

			@include breakpoint(tab_portrait) {
				width: 90%;
			}

			img.logo {
				width: 40rem;

				&.loading {
					animation-name: pulsePlayer;
					animation-duration: 0.5s;
					animation-iteration-count: infinite;
					animation-direction: alternate;
				}
			}

			&-play {
				width: 20rem;
				display: inline-block;
				position: absolute;
				top: 35%;
				cursor: pointer;
				transition: opacity 1s ease;

				&.loading {
					opacity: 0.1;
				}
			}
		}
	}
}

.benefits {
	text-align: center;

	&__comparison {
		&-header {
			display: flex;
			flex-wrap: wrap;

			&__with,
			&__without {
				width: 50%;
			}

			&__with {
				border-right: 1px dashed color(dark-grey);
			}
		}

		&-img {
			width: 100%;

			img {
				object-fit: contain;
			}
		}
	}
}

.home-features {
	background: url('../../images/Wave-1features-bg.svg');

	&__content {
		max-width: 120rem;
		justify-content: center;
		margin: 5rem auto;

		@include breakpoint(tab_portrait) {
			justify-content: center;
		}

		&-card {
			max-width: 35rem;
			height: 25rem;
			margin-bottom: 2rem;
			padding: 2rem 2rem 0 2rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-color: color(white);
			border-radius: 8px;
			margin-right: 2rem;

			@include breakpoint(tab_portrait) {
				max-width: 50rem;
			}

			header {
				align-items: center;
			}

			&__avatar {
				width: 5rem;
				height: 5rem;
				border-radius: 50%;

				img {
					height: 100%;
					object-fit: fill;
				}
			}

			&__footer {
				display: block;
				color: color(indigo);
				border-top: 1px solid color(dark-grey);
				padding: 1.5rem 0;
				margin: 0 -2rem;

				&:active {
					transform: translateY(5px);
				}

				span {
					padding: 0 2rem;
					cursor: pointer;
				}
			}
		}
	}
}

.clients {
	h3 {
		text-align: center;
	}

	background-image: url('../../images/clients-bg.svg');

	&__icons {
		width: 70rem;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin: 3rem auto;

		@include breakpoint(phone) {
			width: 45rem;
		}

		&-container {
			max-width: 20rem;

			img {
				width: 100%;
				object-fit: contain;
			}

			@include breakpoint(phone) {
				width: 10rem;
			}
		}
	}
}
