div.nav_container {
	position: sticky;
	top: 0;
	padding-left: 3.5rem;
	background: color(white);
	z-index: 10000;
}
nav {
	&.navbar {
		margin: 0 5rem;

		@include breakpoint(tab_portrait) {
			margin: 0;
		}
	}
	.navbar-nav {
		align-items: center;
		@include breakpoint(tab_landscape) {
			flex-direction: column;
		}
		.nav__link {
			color: color(black);
			text-transform: capitalize;

			&:hover,
			&:active {
				color: color(indigo);
			}
		}

		.dropdown-menu {
			min-width: 10rem;
			box-shadow: 0px 4px 14px color(dark-grey);
			border: 1px solid color(dark-grey);
			padding: 1rem 1.5rem;
			border-radius: 20px;
			color: color(black);
			font-size: inherit;
			transform-origin: top center;
			@include animate(dropout, 300ms, 1s, ease-in-out, forwards);

			.dropdown-item {
				color: color(black);
				padding: 1rem;
				text-transform: capitalize;

				&:hover {
					background: none;
					color: color(indigo);
				}
			}
		}
	}

	.drawer {
		position: absolute;
		top: 8rem;
		right: 0;
		height: 100vh;
		padding: 2rem;
		width: 20rem;
		background: color(white);
		box-shadow: 0 5px 5px color(indigo);
		@include animate(slideIn, 300ms, 0.2s, ease-out, linear);
	}
}
.navbar-expand-md,
.navbar-nav {
	flex-wrap: wrap;
	justify-content: space-between;
}

.nav__logo {
	width: 10rem;

	img {
		width: 100%;
	}
}
