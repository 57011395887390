.not-found {
	width: 80rem;
	height: 75vh;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include breakpoint(tab_portrait) {
		width: auto;
	}
	h4 {
		font-size: 11rem;
		color: color(indigo);
	}
	p {
		font-size: 3rem;
	}
	span {
		width: 75%;
		margin-bottom: 1rem;
		display: inline-block;
		font-size: 2rem;
		text-align: center;
	}
}
