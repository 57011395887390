.story {
	margin-top: 5rem;
	&-title {
		color: color(indigo);
	}
	&-date {
		font-size: 1.4rem;
		color: color(deep-grey);
	}
	&-sub_title {
		padding-left: 1.5rem;
		font-size: 2rem;
		border-left: 1px solid color(dark-grey);
	}

	&-content {
		display: block;
		min-height: 25rem;
		max-height: 50rem;
		border-bottom: 1px solid color(dark-grey);
		overflow-y: scroll;
		overflow-x: hidden;

		@include breakpoint(tab_portrait) {
			margin-top: 2rem;
		}
	}

	&-image {
		height: 40rem;
		margin-bottom: 2rem;
	}
}
