.faq {
	width: 80rem;
	margin: 0 auto;
	padding: 4rem;
	border-radius: 8px;
	background-color: color(lilac);

	@include breakpoint(tab_portrait) {
		width: auto;
	}

	h5 {
		text-align: center;
	}

	&__content {
		margin-top: 5rem;
		&-question {
			&.toggler {
				content: ' ';
				display: block;
				width: 2rem;
				float: right;
				cursor: pointer;
				img {
					width: 100%;
				}
			}
		}
		&-answer {
			@include animate(dropout, 300ms, 1s, ease-in-out, forwards);
		}
	}
}
