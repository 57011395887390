.carousel {
	background-color: color(indigo);
	background-image: url('../../images/carousel-bg.svg');
	height: 95vh;

	&__item {
		height: 100%;
		display: none;
		opacity: 0.2;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&.active {
			display: flex;
			animation: fadeIn 0.5s forwards;
		}
		&-image {
			width: 65%;
			img {
				width: 100%;
				object-fit: contain;
			}
		}
	}

	&__navigator {
		height: 5rem;
		width: 5rem;
	}
}

.progress {
	width: 70%;
	background-color: #dfdff4;
	border-radius: 8px;
	margin: 0 auto;

	&-bar {
		background-color: color(white);
	}
}
