@each $name, $color in $colors {
	.bg-#{$name} {
		background-color: $color !important;
	}

	.color-#{$name} {
		color: $color !important;
	}
}

@each $name, $size in $margins {
	.mt-#{$name} {
		margin-top: $size !important;
	}

	.mt-sm-#{$name} {
		@include breakpoint(tab_portrait) {
			margin-top: $size !important;
		}
	}

	.mb-#{$name} {
		margin-bottom: $size !important;
	}

	.ms-#{$name} {
		margin-left: $size !important;
	}

	.me-#{$name} {
		margin-right: $size !important;
	}

	.mx-#{$name} {
		margin-left: $size !important;
		margin-right: $size !important;
	}

	.my-#{$name} {
		margin-top: $size !important;
		margin-bottom: $size !important;
	}
	.m-#{$name} {
		margin: $size !important;
	}
}

.desktop {
	display: flex;
	@include breakpoint(tab_landscape) {
		display: none !important;
	}
}

.mobile {
	display: none;
	@include breakpoint(tab_landscape) {
		display: flex !important;
	}
}
.disabled {
	cursor: not-allowed;
}
.cursor-pointer {
	cursor: pointer;
}

@each $name, $weight in $fontWeights {
	.font-#{$name} {
		font-weight: $weight !important;
	}
}

.text-right {
	text-align: right;
}

@each $size, $z-index in $zIndexes {
	.z-index-#{$size} {
		z-index: $z-index;
	}
}
