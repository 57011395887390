.aggrement {
	width: 60%;
	height: 70rem;
	margin: 0 auto 3rem auto;
	overflow-y: scroll;
	overflow-x: hidden;
	box-shadow: 2px -2px 5px 2px #00000021;
	border: #00000021;

	@include breakpoint(tab_portrait) {
		width: 90%;
	}
}
