.btn {
	padding: 1rem 2.5rem;
	border: 0.2px solid color(indigo);
	border-radius: 5px;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	text-transform: capitalize;
	font-family: inherit;
	font-size: inherit;
	transition: all 0.5s ease-out;

	&:hover {
		background-position: left bottom;
	}

	&.white {
		border: 1px solid color(white);
	}

	&-primary {
		background: linear-gradient(
			to right,
			color(white) 50%,
			color(indigo) 50%
		);
		background-position: right bottom;
		background-size: 500% 50%;

		&:hover {
			color: color(indigo);
		}
	}

	&-secondary {
		background-color: color(white);

		background: linear-gradient(
			to right,
			color(indigo) 50%,
			color(white) 50%
		);
		background-size: 500% 50%;
		background-position: right bottom;
		color: color(indigo);
	}

	&__loading {
		position: relative;
		pointer-events: none;
		color: transparent !important;

		&::after {
			position: absolute !important;
			top: calc(50% - (1.4em / 2));
			left: calc(50% - (1.4em / 2));
			display: block;
			border-radius: 50%;
			border: 1px solid color(white);
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			width: 1.4em;
			height: 1.4em;
			animation: rotate 500ms infinite linear;
			transform-origin: center;
			content: '';
		}
	}

	&__loading--alt {
		&::after {
			border: 2px solid color(indigo);
		}
	}
}
