*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%; // 10px === 1 rem
	scroll-behavior: smooth;
	word-spacing: 1px;

	@include breakpoint(min_desktop) {
		font-size: 55%;
	}
	@include breakpoint(tab_landscape) {
		font-size: 50%;
	}
	@include breakpoint(tab_portrait) {
		font-size: 45%;
	}
}

body {
	font-family: 'EuclidCircular', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1.7;
	color: color(text);
	overflow-x: hidden;
	overflow-y: scroll;
}

// /* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	border: NONE;
}

//   /* Track */
::-webkit-scrollbar-track {
	background: color(light-grey);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: color(indigo);
}

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
// 	background: color(lilac);
//   }
