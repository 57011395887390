.payment-modal {
	position: fixed;
	top: 0;
	display: flex;

	width: 100%;
	height: 100%;
	background-color: #00000064;
	&__overlay {
		position: absolute;
		z-index: 700000;
	}

	&__content {
		width: 40%;
		height: 60rem;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: white;
	}

	.close {
		display: inline-block;
		position: absolute;
		top: 0;
		right: 2rem;
		border-radius: 50%;
		font-size: 3.5rem;
		font-weight: 700;
		color: red;

		&:hover {
			cursor: pointer;
		}
	}
}
