.form {
	padding: 5rem;

	&-title {
		padding: 5rem 5rem 1.5rem 5rem;
	}

	&-group {
		position: relative;
		padding: 1rem 2rem;
		margin-bottom: 2rem;
		border: 1px solid rgba(106, 105, 228, 0.2);
		font-size: 1.4rem;
		border-radius: 4px;
		.error-bar {
			display: none;
		}
		&.invalid {
			border-color: red;
			.error {
				&-bar {
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 0.5rem;
					height: 100%;
					background-color: red;
				}
			}
		}

		input,
		textarea {
			border: none;
			outline: none;
			font-size: inherit;

			&:focus,
			&:active {
				outline: none;
				background-color: none;
				border: none;
				box-shadow: none;
			}
			&::placeholder {
				color: color(dark-grey);
			}
		}
	}
	&-control {
		padding: 1rem 0;
	}
	.error-message {
		margin-top: -2rem;
		font-size: 1.2rem;
		color: red;
		text-align: center;
	}
}
