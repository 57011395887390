h1 {
	font-size: 6.5rem;
	font-weight: 500;

	@include breakpoint(min_desktop) {
		font-size: 6rem;
	}

	@include breakpoint(tab_landscape) {
		font-size: 5rem;
	}
}

h2 {
	font-size: 3.5rem;
	font-weight: 500;
}
h3 {
	font-size: 3rem;
	font-weight: 500;
}
h4 {
	font-size: 2.5rem;
	margin-bottom: 2.5rem;
	font-weight: 400;
}
h5 {
	font-size: 2rem;
	margin-bottom: 1rem;
	font-weight: 400;
}
h6 {
	font-size: 1.8rem;
	font-weight: 400;
}

a {
	color: inherit;
	text-decoration: none;

	&:active,
	&:focus,
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

.text-small {
	font-size: 1.2rem;
}
