.testimonial {
	&__navigator,
	&__card {
		width: 70rem;

		@include breakpoint(phone) {
			width: 45rem;
		}
	}
	&__card {
		display: flex;
		position: relative;

		min-height: 30rem;
		padding: 3rem;
		margin: 2rem auto;
		background-color: color(white);
		box-shadow: 0px 4px 120px rgba(67, 102, 118, 0.1);
		border-radius: 2rem;
		transition: all 0.1.5s ease-in-out;
		&-footer {
			margin-top: auto;
		}

		&-content {
			display: none;
			transition: display 1s ease-in-out;
			opacity: 0.2;

			&.active {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				animation: fadeIn 1s forwards;
			}
		}

		&-message {
			line-height: 2;
		}
		&-icon {
			position: absolute;
			top: -5%;
			right: 3%;
			width: 5rem;
			img {
				width: 100%;
			}
		}
	}
	&__client {
		&-avatar {
			display: inline-block;
			width: 6rem;
			height: 6rem;
			background: color(deep-grey);
			border-radius: 50%;

			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	}

	&__navigator {
		display: flex;
		flex-direction: row;
		margin: auto;
		align-items: center;
		justify-content: center;
		div {
			width: 1rem;
			height: 1rem;
			border-radius: 20px;
			background-color: color(dark-grey);

			&:not(:last-child) {
				margin-right: 1rem;
			}

			&.active,
			&:hover {
				background-color: color(indigo);
				cursor: pointer;
			}
		}
	}
}
