.footer {
	&__logo {
		width: 10rem;
		height: 10rem;
	}

	ul {
		padding: none;
		padding-left: 0;
	}
	&__menu {
		list-style-type: none;
		padding: 0;

		li {
			display: inline-block;
			padding: 2rem 0;
			margin-right: 2rem;
		}
	}
}
