.pricing {
	&__header {
		background: url(../../images/clients-bg.svg);
	}

	&-interval {
		margin: 0 2rem;
		color: #4a4a4ab8;
		font-weight: bold;

		&.checked {
			color: color(text);
		}
	}

	&__plan {
		&-feature {
			img {
				width: 5rem;
				height: 5rem;
			}
		}
	}

	&__info {
		padding: 8rem 0;
		border-radius: 8px;
		background: url('../../images//carousel-bg.svg');

		&-title {
			margin-bottom: 0;
			font-size: 3rem;
		}
	}

	&__details {
		display: flex;
		// position: relative;
		max-width: 150rem;
		margin: 0 auto;
		border-radius: 10px;
		background-color: #f8f8ff;
		scrollbar-width: 10px;
		scroll-padding: 2px;
		overflow-x: scroll;
		overflow-y: hidden;

		&-plan {
			position: relative;
			min-width: 38rem;
			padding: 5rem 3rem;
			border-right: 1px solid color(lilac);
			text-align: center;

			&-top-bar {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 101%;
				height: 3%;
				background-color: color(indigo);
			}

			&-icon {
				// width: 4px;
				height: 10rem;
			}

			&-title,
			&-price {
				font-weight: 700;
				font-size: x-large;
			}

			&-copy {
				font-weight: 400;
				font-size: medium;
			}

			&-title {
				display: block;
				margin-top: 1rem;
			}

			&-usercount {
				color: #4a4a4ab8;
			}

			&-description {
				color: #4a4a4ab8;
				margin-bottom: 2rem;
			}

			&-patient-price {
				margin-bottom: 4rem;
				background-color: color(dark-grey);
				border-radius: 5px;
			}
		}
	}
}

.rs-slider {
	display: inline-block;
	width: 70%;
}

.rs-slider-progress-bar {
	color: color(text);
	background-color: color(text) !important;
}

.rs-tooltip {
	display: block;
	background: none;
	color: color(text);
	font-size: 1.2rem;
}

.rs-slider .rs-tooltip {
	display: block;
	opacity: 1;
	bottom: -4rem;
}

.rs-slider-handle.active .rs-tooltip,
.rs-slider-handle:hover .rs-tooltip {
	top: unset;
	bottom: -4rem;
}

.rs-tooltip:after,
.rs-tooltip:before {
	content: none;
}

.rs-slider-handle {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.rs-slider-handle:before {
	width: 3rem;
	height: 3rem;
	top: -1rem;
	border: 1px solid color(text);
	background: color(text);
}
