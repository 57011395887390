$colors: (
	indigo: #6a69e4,
	white: #ffffff,
	light-grey: rgba(198, 198, 244, 0.08),
	lilac: rgba(106, 105, 228, 0.1),
	black: #000505,
	text: #16162d,
	dark-grey: #dfdfe8,
	deep-grey: #9b9b9b,
	red: red
);

$margins: (
	x-sm: 3.5rem,
	sm: 4rem,
	med: 4.5rem,
	lg: 5rem,
	x-lg: 5.5rem
);

$fontWeights: (
	x-sm: 200,
	sm: 300,
	med: 400,
	lg: 500,
	x-lg: 700
);

$zIndexes: (
	sm: 100000,
	med: 200000,
	lg: 300000
);
