.contact {
	background: url('../../images/contact-bg.svg');
	&__form {
		width: 85rem;
		margin: auto;
		border: 1px solid rgba(106, 105, 228, 0.2);
		border-radius: 4px;
		background-color: color(white);

		@include breakpoint(tab_portrait) {
			width: 98%;
		}

		hr {
			color: rgba(106, 105, 228, 0.2);
			opacity: 1;
		}
	}
}
